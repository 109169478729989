import React from 'react'

function NoMatch() {
  return (
    <div>
      <h1 className='text-4xl'>404: Page Not Found</h1>
    </div>
  );
}

export default NoMatch;