import React from 'react'
import { InstagramEmbed } from 'react-social-media-embed';

const Content = () => {
    
    return (
        <div className='max-w-[1240] mx-auto px-4 pb-5  text-white bg-[#385570]'>

            <h1 className='text-4xl text-center mt-0 pt-10'> MM Nostalgie-Galerie </h1>

            <div className='grid lg:grid-cols-3'>

                {/* FIRST ROW*/}

                <div className='bg-white flex flex-col mt-[2rem] p-1 m-2 rounded-lg'>
                    <img src={require('../assets/MM_Live1.jpg')} alt='Mission Maibaum Live On Stage in Augsburg' />
                </div>

                <div className='bg-white flex flex-col mt-[2rem] p-1 m-2 rounded-lg'>
                    <img src={require('../assets/MM_Live3.jpg')} alt='Mission Maibaum Live On Stage im Englischen Garten' />
                </div>

                <div className='bg-white flex flex-col mt-[2rem] p-1 m-2 rounded-lg'>
                    <img src={require('../assets/MM_Live2.jpg')} alt='Mission Maibaum Live On Stage bei Ludwig und Kunst' />
                </div>

                {/* SECOND ROW*/}

                <div className='bg-white flex flex-col mt-[1rem] p-1 m-2 rounded-lg'>
                    <img src={require('../assets/MM_Live4.jpg')} alt='Mission Maibaum Live On Stage im Englischen Garten' />
                </div>

                <div className='bg-white flex flex-col mt-[1rem] p-1 m-2 rounded-lg'>
                    <img src={require('../assets/MM_Live5.jpg')} alt='Mission Maibaum Live On Stage beim Songslam' />
                </div>

                <div className='bg-white flex flex-col mt-[1rem] p-1 m-2 rounded-lg'>
                    <img src={require('../assets/MM_Live7.jpg')} alt='Mission Maibaum Live On Stage' />
                </div>

                {/* THIRD ROW*/}

                <div className='bg-white flex flex-col mt-[1rem] p-1 m-2 rounded-lg'>
                    <InstagramEmbed url="https://www.instagram.com/p/Bwzn-MHoVw6/" />
                </div>
                <div className='bg-white flex flex-col mt-[1rem] p-1 m-2 rounded-lg'>
                    <InstagramEmbed url="https://www.instagram.com/p/BksNS-Mjl1-/" />
                </div>
                <div className='bg-white flex flex-col mt-[1rem] p-1 m-2 rounded-lg'>
                    <InstagramEmbed url="https://www.instagram.com/p/Bexu7tPn5CF/"/>
                </div>

                {/* FOURTH ROW*/}


                <div className='bg-white flex flex-col mt-[1rem] p-1 m-2 rounded-lg'>
                    <InstagramEmbed url="https://www.instagram.com/p/B3fJQaxIXTk/" />
                </div>
                <div className='bg-white flex flex-col mt-[1rem] p-1 m-2 rounded-lg'>
                    <InstagramEmbed url="https://www.instagram.com/p/Biz7fdJj3Nl/" />
                </div>
                <div className='bg-white flex flex-col mt-[1rem] p-1 m-2 rounded-lg'>
                    <InstagramEmbed url="https://www.instagram.com/p/Bz3J8iRoIFO/" />
                </div>

            </div>


           
        </div>
    )
}

export default Content