import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Home from './pages/Home.tsx';
import Imprint from './pages/Imprint.tsx';
import NoMatch from './pages/NoMatch.tsx';
import Privacy from './pages/Privacy.tsx';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Home />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<NoMatch />} />
      </Routes>
    </Router>
  );
}

export default App;
