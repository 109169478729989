import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    
    return (
        <div className='mx-auto lg:px-4 text-white'>
            
            <p className='flex justify-center py-10' >
                ©2024 Mission Maibaum &nbsp;|&nbsp;<Link to="/imprint" className='border-b-[1px] border-b-[#51AACA] hover:border-b-white'>Impressum</Link> &nbsp;|&nbsp;<Link to="/privacy" className='border-b-[1px] border-b-[#51AACA] hover:border-b-white'>Datenschutz</Link>
            </p>
           
        </div>
    )
}

export default Footer
