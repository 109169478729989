import React from 'react';
import Navbar from '../components/Navbar.tsx';
import Hero from '../components/Hero.tsx';
import Content from '../components/Content.tsx';
import Footer from '../components/Footer.tsx';

function Home() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Content />
      <Footer />
    </div>
  );
}

export default Home;