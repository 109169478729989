import React from 'react'

const Navbar = () => {
    
    return (
        <div className='w-full bg-[#ffffff] pt-4 px-4'>
            <img className="w-[800px] mx-auto" src={require('../assets/cropped-MM_Logo_vs2.png')} alt='Schriftzug Christan Orth' />
        </div>
    )
}

export default Navbar