import React from 'react'

const Hero = () => {
    
    return (
        <div className='w-full text-white'>

            <h1 className='text-2xl text-center my-10 2xl:text-2xl'>
                Wir sind MISSION MAIBAUM vom Ammersee und machen feinste Gitarrenmusik!
            </h1>
            
            <div className='bg-white w-800 mx-20 rounded-md m-10 h-[1px]' />
            
            <div className='mx-auto justify-center items-center grid lg:grid-cols-2 '>

                {/* LEFT COLUMN */}

                <div className='hidden sm:flex justify-center items-center'>
                    <iframe width={500} height={350} src="https://www.youtube-nocookie.com/embed/CFQsv1rUTwg?si=TmIK9a6tb6OSAQDe&amp;controls=1" title="Mission Maibaum Album Trailer" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>

                <div className='flex justify-center items-center sm:hidden'>
                    <iframe width={300} height={200} src="https://www.youtube-nocookie.com/embed/CFQsv1rUTwg?si=TmIK9a6tb6OSAQDe&amp;controls=1" title="Mission Maibaum Album Trailer" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>

                {/* RIGHT COLUMN */}

                <div className='sm:text-xl text-lg mt-10 lg:mt-0 px-10'>
                    <p>
                        Leider mussten wir unsere Endless-Rubber-Duck-Tour während der Corona-Zeit unterbrechen und legen gerade 
                        ein kreatives Päuschen ein &#128564;&#128564;&#128564;
                    </p>
                    <p className='my-3'>
                        Aber keine Angst! Ihr hört bestimmt bald wieder von uns mit neuen Ideen und frischen Wind für die Seele.
                        Bis dahin könnt ihr natürlich weiterhin fleißig unsere zeitlosen Klassiker auf Spotify und Co. genießen oder in der 
                        MM Nostalgie-Galerie in den guten alten Zeiten schwelgen :)
                    </p> 
                    <p>
                        Bis dann und für euch nur das Beste &#128075;
                    </p> 
                    <p className='my-3'>
                        ~ Korbi und Chris
                    </p> 

                </div>
                

                    
            </div>

            <div className='bg-white w-800 mx-20 mt-10 rounded-md h-[1px]' />

            <div className='mx-auto grid lg:grid-cols-2 p-10'>

                <div className='flex lg:justify-end justify-center items-center space-x-20 lg:pr-10'>
                        <a href="https://open.spotify.com/intl-de/artist/2O64UvFB3iqxARCuWm5x2H" target='_blank' ><img className="w-[150px] hover:scale-105" src={require('../assets/stream_spotify.png')} alt='Streame Mission Maibaum auf Spotify' /></a>
                        <a href="https://www.amazon.de/music/player/artists/B07L58ZKQ7/mission-maibaum" target='_blank' ><img className="w-[150px] hover:scale-105" src={require('../assets/stream_amazon.png')} alt='Streame Mission Maibaum auf Amazon Music' /></a>                        
                </div>

                <div className='flex lg:justify-start justify-center items-center space-x-20 mt-5 lg:mt-0 lg:pl-10'>
                        <a href="https://music.apple.com/us/artist/mission-maibaum/1445773206" target='_blank' ><img className="w-[150px] hover:scale-105" src={require('../assets/stream_apple.png')} alt='Streame Mission Maibaum auf Apple Music' /></a>
                        <a href="https://music.youtube.com/channel/UC5Y6w7ymwZrzZ2zfT85SA6w" target='_blank' ><img className="w-[150px] hover:scale-105" src={require('../assets/stream_youtube.png')} alt='Streame Mission Maibaum auf Youtube Music' /></a>
                </div>

            </div>

               
        </div>
    )
}

export default Hero